import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { css } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';
import TextsmsTwoToneIcon from '@mui/icons-material/TextsmsTwoTone';
import LocalPostOfficeTwoToneIcon from '@mui/icons-material/LocalPostOfficeTwoTone';
import { PrimaryButton, SecondaryButton } from '@/common/components/buttons';
import { useTranslation } from 'next-i18next';
import { ReservationItem } from '@/common/types/reservation';
import { useCustomerNameFmt } from '@/common/hooks/use-customers';
import { ticketColor } from '@/common/types/ticket.base';
import useI18nTimeUtils from '@/common/i18n-time-utils';
import { useFeatureFlags } from '@/common/hooks/use-feature-flag';
import { usePwaOrMobileView } from '@/common/hooks/use-pwa';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';

const DisabledText = styled('span')(({ theme }) => ({
  color: theme.palette.grey[500],
  textDecoration: 'underline',
  textUnderlineOffset: '-40%',
  textDecorationSkipInk: 'none',
}));

const RightArrow = styled('span')(({ theme }) => ({
  padding: `0 ${theme.spacing(0.4)}`,
}));

const CircleTicketColor = styled('span')(({ color }) => ({
  background: color,
  width: 10,
  height: 10,
  borderRadius: 900,
  marginRight: 8,
  marginTop: 3,
}));

export interface DataReservationConfirmation {
  userName: string;
  email?: string;
  phoneNumber?: string;

  ticketName: string;
  ticketColor: string;
  date: string;
  time: string;
  numAdults: number;
  numChildren: number;
  tableNo: number;

  specialRequest?: string;
  staffNote?: string;
  occasions?: string;
}

export interface DataBeforeEditedReservation
  extends Partial<DataReservationConfirmation> {}

interface Props {
  dataBeforeEdited?: ReservationItem | null;
  data: ReservationItem | null;
  onClose: () => void;
  autoHide?: number; // seconds
  redirectToViewModal?: (reservationId: string) => void;
}

const ReservationConfirmation = (props: Props) => {
  const { dataBeforeEdited, data, onClose, autoHide, redirectToViewModal } =
    props;
  const { t } = useTranslation();
  const { formatDate, secondsToHrsMinsDecimal } = useI18nTimeUtils();
  const theme = useTheme();
  const formatUser = useCustomerNameFmt();
  const { isEnableTableCombination } = useFeatureFlags();
  const { isMobileView } = usePwaOrMobileView();

  const {
    reservationDate: oldReservationDate,
    reservationTime: oldReservationTime,
    numberOfAdults: oldNumberOfAdult,
    numberOfChildren: oldNumberOfChildren,
    tables: oldTables,
    occasions: oldOccasions,
    notes: oldNotes,
    staffNotes: oldStaffNotes,
    user: oldUser,
    ticket: oldTicket,
    rDateTime: rOldDateTime,
    diningInterval: oldDiningInterval,
  } = dataBeforeEdited || {};

  const {
    reservationDate,
    reservationTime,
    numberOfAdults,
    numberOfChildren,
    tables,
    occasions,
    notes,
    staffNotes,
    user,
    ticket,
    rDateTime,
    isWalkIn,
    diningInterval,
  } = data || {};

  const isNotifyByEmail = !!data?.allowEmailNotify && !!user?.email;
  const isNotifyByPhone = !!data?.allowSmsNotify && !!user?.phone;

  const shouldShowNotifications = Boolean(
    (isNotifyByEmail || isNotifyByPhone) &&
      (oldTicket?.name || // exist at least 1 field is changed
        oldReservationDate ||
        oldReservationTime ||
        oldNumberOfAdult ||
        oldNumberOfChildren ||
        !dataBeforeEdited)
  );

  const [hideAfter, setHideAfter] = useState(autoHide || 0);

  const getAdultLabel = (numAdults = 0) => {
    if (!numAdults) return;
    if (numAdults === 1) return t('Adult');
    return t('Adults');
  };

  const getChildLabel = (numChildren = 0) => {
    if (!numChildren) return;
    if (numChildren === 1) return t('Child');
    return t('Children');
  };

  const renderSpecialRequest = () => {
    if (!dataBeforeEdited) return null;

    const listSpecialRequest = [];

    if (oldOccasions !== occasions) {
      listSpecialRequest.push(t('Occasions'));
    }
    if (oldNotes !== notes) {
      listSpecialRequest.push(t('Special Requests'));
    }
    if (oldStaffNotes !== staffNotes) {
      listSpecialRequest.push(t('Staff Notes'));
    }

    if (!listSpecialRequest.length) return null;

    return (
      <Typography
        variant="body2"
        sx={{ color: 'grey.600', fontSize: 12, mt: 1 }}
      >{`${t('Edited')}: ${listSpecialRequest.join(', ')}`}</Typography>
    );
  };

  useEffect(() => {
    if (!hideAfter) return;
    const updateTime = setTimeout(() => {
      setHideAfter(hideAfter - 1);
    }, 1000);
    return () => {
      clearTimeout(updateTime);
      if (hideAfter === 1) onClose();
    };
  }, [hideAfter, onClose]);
  const oldTableNames = useMemo(
    () => oldTables?.map((table) => table?.name).join(', '),
    [oldTables]
  );
  const newTableNames = useMemo(
    () => tables?.map((table) => table?.name).join(', '),
    [tables]
  );
  return (
    <Dialog
      open
      onClose={onClose}
      fullScreen={isMobileView}
      css={css`
        & > .MuiBackdrop-root {
          background: rgba(22, 28, 36, 0.72);
        }
        & .MuiDialog-paper {
          width: ${isMobileView ? '100%' : '600px'};
          max-width: 100%;
          margin: 0;
        }
      `}
    >
      <DialogTitle
        css={css`
          padding: 14px 14px 0;
          text-align: end;
        `}
      >
        {!isMobileView && (
          <IconButton onClick={onClose}>
            <CloseIcon sx={{ fontSize: 28 }} />
          </IconButton>
        )}
      </DialogTitle>

      <DialogContent
        css={css`
          text-align: center;
          padding: 4px 24px 16px;
        `}
      >
        <Box sx={{ mb: 2, mt: isMobileView ? 2 : 0 }}>
          <CheckCircleTwoToneIcon sx={{ fontSize: 100, color: '#4EAD13' }} />
        </Box>
        <Box>
          <Typography variant="h5" sx={{ mb: 1 }}>
            {isWalkIn ? (
              <>
                {dataBeforeEdited ? t('Walk-in Edited!') : t('Walk-in Seated!')}
              </>
            ) : (
              <>
                {dataBeforeEdited
                  ? t('Reservation Edited!')
                  : t('Reservation Added!')}
              </>
            )}
          </Typography>

          <Typography variant="subtitle2">
            {!!(
              oldUser &&
              (user ? formatUser(oldUser) !== formatUser(user) : true)
            ) && (
              <>
                <DisabledText>{oldUser && formatUser(oldUser)}</DisabledText>
                <RightArrow>{'->'}</RightArrow>
              </>
            )}
            {user && formatUser(user)}
          </Typography>
          {ticket && (
            <Typography variant="subtitle2" className="div-center">
              {oldTicket?.name && oldTicket?.name !== ticket?.name && (
                <>
                  <CircleTicketColor
                    color={ticketColor(oldTicket?.color).swatch}
                  />
                  <DisabledText>{oldTicket?.name}</DisabledText>
                  <RightArrow>{'->'}</RightArrow>
                </>
              )}
              <CircleTicketColor color={ticketColor(ticket?.color).swatch} />
              {ticket?.name}
            </Typography>
          )}

          <Typography variant="subtitle2">
            {!!(
              oldReservationDate &&
              rOldDateTime &&
              (oldReservationDate !== reservationDate ||
                oldReservationTime !== reservationTime)
            ) && (
              <>
                <DisabledText>
                  {formatDate(rOldDateTime, {
                    en: 'EEE dd LLL, h:mm a',
                    zh: 'EEE LLL do, h:mm a',
                  })}
                </DisabledText>
                <RightArrow>{'->'}</RightArrow>
              </>
            )}
            {!!rDateTime &&
              formatDate(rDateTime, {
                en: 'EEE dd LLL, h:mm a',
                zh: 'EEE LLL do, h:mm a',
              })}
          </Typography>
          {!!(isWalkIn && diningInterval) && (
            <Typography variant="subtitle2">
              {t('Dining Interval')}{' '}
              {!!(
                oldDiningInterval && oldDiningInterval !== diningInterval
              ) && (
                <>
                  <DisabledText>
                    {secondsToHrsMinsDecimal(oldDiningInterval)}
                  </DisabledText>
                  <RightArrow>{'->'}</RightArrow>
                </>
              )}
              {secondsToHrsMinsDecimal(diningInterval)}
            </Typography>
          )}

          <Typography variant="subtitle2">
            {!!(
              oldNumberOfAdult &&
              (oldNumberOfAdult !== numberOfAdults ||
                oldNumberOfChildren !== numberOfChildren)
            ) && (
              <>
                <DisabledText>
                  {oldNumberOfAdult} {getAdultLabel(oldNumberOfAdult)}
                </DisabledText>
                {!!oldNumberOfChildren && (
                  <DisabledText>
                    , {oldNumberOfChildren} {getChildLabel(oldNumberOfChildren)}
                  </DisabledText>
                )}
                <RightArrow>{'->'}</RightArrow>
              </>
            )}
            {numberOfAdults} {getAdultLabel(numberOfAdults)}
            {!!numberOfChildren && (
              <>
                , {numberOfChildren} {getChildLabel(numberOfChildren)}
              </>
            )}
          </Typography>
          <Typography variant="subtitle2">
            {t('Table No.')}{' '}
            {isEnableTableCombination ? (
              oldTableNames !== newTableNames ? (
                <>
                  <DisabledText>
                    {oldTables?.map((table) => table?.name).join(', ')}
                  </DisabledText>
                  <RightArrow>{'->'}</RightArrow>
                  <Typography variant="subtitle2" component="span">
                    {newTableNames}
                  </Typography>
                </>
              ) : (
                <Typography variant="subtitle2" component="span">
                  {newTableNames}
                </Typography>
              )
            ) : (
              !!(
                oldTables?.length && oldTables?.[0]?.name !== tables?.[0]?.name
              ) && (
                <>
                  <DisabledText>{oldTables?.[0]?.name}</DisabledText>
                  <RightArrow>{'->'}</RightArrow>
                  <Typography variant="subtitle2" component="span">
                    {tables?.[0]?.name}
                  </Typography>
                </>
              )
            )}
          </Typography>
          {renderSpecialRequest()}
        </Box>

        {!isWalkIn && (
          <>
            <Divider sx={{ my: 2 }} />

            {shouldShowNotifications ? (
              <>
                {/* Show phone number & email */}
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ color: theme.palette.grey[600], mb: 1 }}
                  >
                    {t('Notifications have been sent via')}
                  </Typography>
                  <Box className="div-center">
                    {isNotifyByPhone && (
                      <Typography variant="body2" className="div-center">
                        <TextsmsTwoToneIcon
                          sx={{ mr: 1, color: theme.palette.grey[600] }}
                        />
                        {user?.phone}
                      </Typography>
                    )}
                    {isNotifyByEmail && isNotifyByPhone && (
                      <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
                    )}
                    {isNotifyByEmail && (
                      <Typography variant="body2" className="div-center">
                        <LocalPostOfficeTwoToneIcon
                          sx={{ mr: 1, color: theme.palette.grey[600] }}
                        />
                        {user?.email}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </>
            ) : (
              <Typography
                variant="body2"
                sx={{ color: theme.palette.grey[600], mb: 2 }}
              >
                {t('No notifications have been sent.')}
              </Typography>
            )}
          </>
        )}
      </DialogContent>

      <DialogActions>
        {/* Click view button, open View Reservation/WalkIn Modal */}
        {isMobileView ? (
          <Box sx={{ flexDirection: 'column', width: '100%' }}>
            <PrimaryButton
              css={css`
                width: 100%;
                height: 48px;
              `}
              sx={{ mb: 2 }}
              onClick={onClose}
            >
              {t('Close')} {!!hideAfter && <>({hideAfter}s)</>}
            </PrimaryButton>
            <SecondaryButton
              css={css`
                width: 100%;
                height: 48px;
              `}
              autoFocus
              onClick={() => {
                redirectToViewModal && data && redirectToViewModal(data.id);
              }}
            >
              {t('View')}
            </SecondaryButton>
          </Box>
        ) : (
          <>
            <SecondaryButton
              css={css`
                width: 50%;
              `}
              autoFocus
              onClick={() => {
                redirectToViewModal && data && redirectToViewModal(data.id);
              }}
            >
              {t('View')}
            </SecondaryButton>
            <PrimaryButton
              css={css`
                width: 50%;
              `}
              onClick={onClose}
            >
              {t('Close')} {!!hideAfter && <>({hideAfter}s)</>}
            </PrimaryButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ReservationConfirmation;
